<template>
  <div class="carousel">
    <el-carousel trigger="click" :autoplay="false" :height="carHeight">
      <el-carousel-item v-for="item in 3" :key="item">
        <div class="carousel-cont">
          <img
            ref="bannerRef"
            class="banner"
            src="@/assets/img/home/banner.png"
          />
          <div class="cont-box" :style="{ height: carHeight }">
            <div class="cont">
              <div class="cont-tit">{{ $t('index_banner_1') }}</div>
              <div class="cont-tip">
                {{ $t('index_banner_2') }}
              </div>
              <el-button
                @click="jumpTo('Conversion')"
                size="small"
                type="primary"
              >
                {{ $t('立即开始兑换') }}
              </el-button>
            </div>
          </div>
        </div>
      </el-carousel-item>
    </el-carousel>
    <div class="ban">
      <div class="cont-bottom flex-start">
        <div class="item">
          {{ $t('index_banner_4') }}
        </div>
        <div class="item">
          {{ $t('index_banner_5') }}
        </div>
        <div class="item ls">
          {{ $t('index_banner_6') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isLogin } from '@/utils/tool.js'
export default {
  data() {
    return {
      ruleForm: {},
      carHeight: '640px',
    }
  },
  created() {
    this.getCarHeight()
    window.addEventListener('resize', this.getCarHeight)
  },
  methods: {
    getCarHeight() {
      const clientWidth = document.documentElement.clientWidth
      this.carHeight = (clientWidth / 1920) * 640 + 'px'
    },
    jumpTo(pageName) {
      if (!isLogin()) {
        this.$message({
          message: '请先登录',
          type: 'warning',
        })
        this.$router.push({ name: 'Login' })
        return
      }
      this.$router.push({ name: pageName })
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.getCarHeight)
  },
}
</script>

<style scoped lang="scss">
.carousel {
  /deep/ .el-carousel__indicators--horizontal {
    bottom: 40px;

    .el-carousel__button {
      width: 8px;
      height: 8px;
      border-radius: 50%;
    }
  }
}

.carousel-cont {
  position: relative;

  .banner {
    width: 100%;
  }
}

.cont-box {
  position: absolute;
  width: 1200px;
  left: 0;
  right: 0;
  top: 0;
  margin: auto;

  .cont {
    position: absolute;
    bottom: 0;
    top: 0;
    left: 40px;
    margin: auto;
    width: 480px;
    height: 280px;
    color: #000000;

    .cont-tit {
      font-size: 56px;
      font-weight: bold;
    }

    .cont-tip {
      font-size: 24px;
      margin: 20px 0 60px;
    }
  }
}

.cont-bottom {
  position: relative;
  top: -40px;
  width: 1000px;
  height: 80px;
  background: #ffffff;
  border-radius: 8px;
  padding: 16px;
  margin: 0 auto;
  z-index: 2;

  .item {
    padding: 0 30px;
    border-right: 1px solid #c4c4c4;
    font-size: 14px;
    color: #333333;
    display: flex;
    align-items: center;
    text-align: center;
    width: 33.33%;

    &.ls {
      border: 0;
      padding-left: 40px;
    }
  }
}
</style>
