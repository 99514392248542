<template>
  <div class="business mainBox">
    <div class="tit">
      {{ $t('index_business_1') }}
    </div>
    <div class="business-cont">
      <div class="business-item-cont">
        <div class="user">{{ $t('index_business_2') }}</div>
        <el-tag class="tag" size="small" type="warning">
          {{ $t('index_business_3') }}
        </el-tag>
        <div class="tip">
          {{ $t('index_business_4') }}
        </div>
        <el-button @click="toMy('bankCard')" size="small" type="primary">
          {{ $t('index_business_5') }}
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { isLogin } from '@/utils/tool.js'
export default {
  methods: {
    toMy() {
      if (!isLogin()) {
        this.$message({
          message: '请先登录',
          type: 'warning',
        })
        this.$router.push({ name: 'Login' })
        return
      }
      this.$router.push({
        name: 'My',
      })
    },
  },
}
</script>

<style scoped lang="scss">
.business {
  .tit {
    font-size: 30px;
    font-weight: bold;
    color: #000000;
    text-align: center;
  }
}

.business-cont {
  background: url(../../assets/img/home/business.png) no-repeat;
  background-size: 100% 100%;
  height: 480px;
  margin-top: 16px;
  position: relative;
}
</style>
