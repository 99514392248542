<template>
  <div class="business mainBox" id="remittance">
    <div class="tit">
      {{ $t('index_bank_1') }}
    </div>
    <div class="tit-tip">
      {{ $t('index_bank_2') }}
    </div>
    <div class="business-cont">
      <div class="business-item-cont left">
        <div class="user">{{ $t('index_bank_3') }}</div>
        <el-tag class="tag" size="small" type="warning">
          {{ $t('index_bank_4') }}
        </el-tag>
        <div class="tip">
          {{ $t('index_bank_5') }}
        </div>
        <el-button @click="toMy('bankCard')" size="small" type="primary">
          {{ $t('index_bank_6') }}
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { isLogin } from '@/utils/tool.js'
export default {
  created() {
    this.watchEvent()
  },
  methods: {
    toMy() {
      if (!isLogin()) {
        this.$message({
          message: '请先登录',
          type: 'warning',
        })
        this.$router.push({ name: 'Login' })
        return
      }
      this.$router.push({
        name: 'My',
      })
    },
    anchorScroll() {
      const anchorEle = document.querySelector('#remittance')
      anchorEle.scrollIntoView(true)
    },
    watchEvent() {
      let that = this
      this.$root.eventHub.$on('anchorScroll', () => {
        that.anchorScroll()
      })
    },
  },
}
</script>

<style scoped lang="scss">
.business {
  margin-top: 45px;

  .tit {
    font-size: 30px;
    font-weight: bold;
    color: #333333;
    text-align: center;
  }

  .tit-tip {
    font-size: 12px;
    color: #999999;
    margin-top: 6px;
    text-align: center;
  }
}

.business-cont {
  background: url(../../assets/img/home/remittance.png) no-repeat;
  background-size: 100% 100%;
  height: 480px;
  margin-top: 16px;
  position: relative;
}
</style>
