<template>
  <div class="home">
    <!-- 轮播图 -->
    <carousel></carousel>
    <!-- 商务 -->
    <business></business>
    <!-- 银行卡 -->
    <remittance></remittance>
    <!-- 货币 -->
    <conversion></conversion>
    <!-- 常见问题 -->
    <issue></issue>
    <!-- 联系我们 -->
    <div class="contactFix" @click="jumpTo('Contact')">
      <i class="icon el-icon-service"></i>
      {{ $t('index_con_1') }}
    </div>
  </div>
</template>

<script>
import carousel from './carousel.vue'
import business from './business.vue'
import remittance from './remittance.vue'
import conversion from './conversion.vue'
import issue from './issue.vue'
export default {
  name: 'Home',
  components: {
    carousel,
    business,
    remittance,
    conversion,
    issue,
  },
  methods: {
    jumpTo(pageName) {
      this.$router.push({ name: pageName })
    },
  },
}
</script>

<style lang="scss">
.business-item-cont {
  position: absolute;
  top: 160px;
  right: 60px;
  width: 380px;
  &.left {
    left: 60px;
    right: initial;
  }
  .user {
    font-size: 18px;
    font-weight: bold;
    color: #333333;
  }
  .tag {
    background: rgba(149, 71, 248, 0.1);
    color: #9547f8;
    border: 0;
    margin: 7px 0 16px;
  }
  .tip {
    font-size: 12px;
    color: #666666;
    margin-bottom: 70px;
  }
}
.contactFix {
  position: fixed;
  width: 40px;
  height: 112px;
  z-index: 10;
  right: 100px;
  bottom: 200px;
  padding: 10px;
  text-align: center;
  font-size: 12px;
  color: #ffffff;
  background: url(../../assets/img/home/contactfit.png) no-repeat;
  cursor: pointer;
  word-wrap: break-word;
  .icon {
    font-size: 20px;
    margin-bottom: 4px;
  }
}
</style>
