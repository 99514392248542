<template>
  <div class="business">
    <div class="business-cont mainBox" :style="{ height: carHeight }">
      <div class="business-item-cont">
        <div class="user">{{ $t('index_conversion_1') }}</div>
        <el-tag class="tag" size="small" type="warning">
          {{ $t('index_conversion_2') }}
        </el-tag>
        <div class="tip">
          {{ $t('index_conversion_3') }}
        </div>
        <el-button @click="jumpTo('Conversion')" size="small" type="primary">
          {{ $t('index_conversion_4') }}
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      carHeight: '480px',
    }
  },
  methods: {
    jumpTo(pageName) {
      this.$router.push({ name: pageName })
    },
  },
}
</script>

<style scoped lang="scss">
.business {
  background: url(../../assets/img/home/conversion.png) no-repeat;
  background-size: 100% 100%;
}

.business-cont {
  margin-top: 16px;
  position: relative;

  .business-item-cont {
    height: 205px;
    bottom: 0;
    top: 0;
    margin: auto;
  }
}
</style>
