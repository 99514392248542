<template>
  <div class="issue mainBox">
    <div class="tit">
      {{ $t('index_issue_1') }}
    </div>
    <div class="issue-cont">
      <div class="issue-item-cont">
        <div class="issue-tit flex-between">
          <div class="name">{{ $t('index_issue_2') }}</div>
          <div class="right">
            {{ $t('index_issue_3') }}
            <i class="el-icon-arrow-right"></i>
          </div>
        </div>
        <div class="issue-list">
          <el-collapse v-model="activeName" accordion>
            <el-collapse-item name="1">
              <template slot="title">
                <div class="issue-list-tit">
                  这是一个新闻消息，这这是一个新这这是一个新这这是一个新闻消息，这这是一个新
                </div>
              </template>
              <div>
                与现实生活一致：与现实生活的流程、逻辑保持一致，遵循用户习惯的语言和概念；
              </div>
              <div>
                在界面中一致：所有的元素和结构需保持一致，比如：设计样式、图标和文本、元素的位置等。
              </div>
            </el-collapse-item>
            <el-collapse-item title="这是一个新闻消息" name="2">
              <div>
                控制反馈：通过界面样式和交互动效让用户可以清晰的感知自己的操作；
              </div>
              <div>
                页面反馈：操作后，通过页面元素的变化清晰地展现当前状态。
              </div>
            </el-collapse-item>
            <el-collapse-item title="这是一个新闻消息" name="3">
              <div>简化流程：设计简洁直观的操作流程；</div>
              <div>
                清晰明确：语言表达清晰且表意明确，让用户快速理解进而作出决策；
              </div>
              <div>
                帮助用户识别：界面简单直白，让用户快速识别而非回忆，减少用户记忆负担。
              </div>
            </el-collapse-item>
            <el-collapse-item title="这是一个新闻消息" name="4">
              <div>
                用户决策：根据场景可给予用户操作建议或安全提示，但不能代替用户进行决策；
              </div>
              <div>
                结果可控：用户可以自由的进行操作，包括撤销、回退和终止当前操作等。
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
      </div>
      <img class="phoeImg" src="@/assets/img/home/phoe.png" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeName: 1,
    }
  },
  methods: {
    getCarHeight() {
      const clientWidth = document.documentElement.clientWidth
      this.carHeight = (clientWidth / 1920) * 640 + 'px'
    },
  },
}
</script>

<style scoped lang="scss">
.issue {
  margin-top: 45px;
  margin-bottom: 36px;
  .tit {
    font-size: 30px;
    font-weight: bold;
    color: #333333;
    text-align: center;
  }
}
.issue-cont {
  background: url(../../assets/img/home/issue.png) no-repeat;
  background-size: 100% 100%;
  margin-top: 96px;
  position: relative;
  min-height: 400px;
  padding-top: 40px;
  padding-bottom: 20px;
}
.issue-item-cont {
  width: 480px;
  background: #ffffff;
  border-radius: 16px;
  padding: 16px;
  margin-left: 60px;
  .issue-tit {
    font-size: 12px;
    margin-bottom: 10px;
    .name {
      color: #333333;
    }
    .right {
      color: #999999;
      cursor: pointer;
    }
  }
  .issue-list-tit {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 450px;
  }
  /deep/ .el-collapse {
    .el-collapse-item__header {
      font-size: 12px;
      color: #666666;
    }
    .el-collapse-item__content {
      font-size: 12px;
    }
  }
}
.phoeImg {
  position: absolute;
  right: 0;
  top: -84px;
}
</style>
